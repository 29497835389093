<template>
    <ul class="listContainer" :style="{ width: width + '%' }">
        <slot></slot>
    </ul>
</template>

<script>
    export default{
        props: {
            width: {
                type: Number,
                default: 85
            }
        }
    }
</script>

<style scoped="">
    ul{
        margin: 0 auto;
    }
</style>