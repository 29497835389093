<template>
  <div id="mainFrame">
    <PageTitle :navBar="false"
               height="28vh">
      <p class="hospitalTitle"><span>康宁云医院测试版</span></p>
      <van-icon name="comment"
                color="#fff"
                size="1.5rem"
                @click="gotoMenu"
                :badge="num!=0?num:''" />
    </PageTitle>
    <div class="mainContainer"
         :style="{ top: offset }">
      <slot></slot>
    </div>
    <van-tabbar v-model="active"
                @change="changeTab"
                :active-color="variables.green"
                fixed
                placeholder>
      <van-tabbar-item name="home"
                       to="/">
        <span>我的医院</span>
        <template #icon="props">
          <img :src="props.active ? hospital.active : hospital.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="self"
                       to="/self">
        <span>个人主页</span>
        <template #icon="props">
          <img :src="props.active ? self.active : self.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import variables from '@/style/var.less';
import { getDefaultAccount } from '@/api/user.js';
import { GetInforMationUnRead } from '@/api/check.js';
// import wx from 'weixin-js-sdk';

export default {
  name: 'Home',
  components: {
    PageTitle
  },
  props: {
    offset: {
      type: String,
      default: '0rem'
    }
  },
  data () {
    return {
      active: this.$store.getters.activeTab,
      hospital: {
        active: require('../../assets/hospital-selected.png'),
        inactive: require('../../assets/hospital-noSelected.png'),
      },
      self: {
        active: require('../../assets/self-selected.png'),
        inactive: require('../../assets/self-noSelected.png'),
      },
      variables: variables,
      num: '0',
      patientid: '',
      item: '',
      heartbeatInstance: null, //心跳检测实例
      isDestroyed: false, //判断websocket是否主动销毁
      socket: null,
    };
  },
  methods: {
    changeTab (active) {
      this.$store.dispatch('pageData/setActiveTab', active);
    },
    gotoMenu () {
      //调用小程序里写好的请求订阅函数。
      // let postData = {
      //   "push": "1"
      // }
      // wx.miniProgram.redirectTo({ url: `/pages/webview/webview?push=1` });
      //通过push进行跳转
      this.$router.push('/message');
    },
    heartbeat () {
      this.socket.send(JSON.stringify({
        msg: '',
        msgtype: "6",
        senderid: 'patient-' + this.patientid,
        targetId: -1,
        id: ''
      }));
    },
    doConnect () {
      let that = this;

      if (this.$store.getters.SaveSocket && this.$store.getters.SaveSocket.readyState == WebSocket.OPEN) {
        this.socket = this.$store.getters.SaveSocket;
      }
      else {
        let uri = process.env.VUE_APP_API_WSS_URL + '/ws?senderId=' + this.patientid + '&targetId=' + '-1'
        this.socket = new WebSocket(uri);
        this.$store.getters.SaveSocket = this.socket;
      }

      this.socket.onopen = function () {
        that.heartbeatInstance = setInterval(() => {
          if (that.socket && that.socket.readyState == WebSocket.OPEN) {
            that.heartbeat();
          } else {
            if (that.heartbeatInstance) {
              clearInterval(that.heartbeatInstance);
            }
          }
        }, 50000, that)
      };

      this.socket.onclose = function () {
        if (!that.isDestroyed) {
          setTimeout(() => {
            that.doConnect();
          }, 5000, that)
        }
      };

      this.socket.onmessage = function (e) {
        let msg = JSON.parse(e.data);
        that.num = msg.totalUnReadNum;
      }
    },
  },
  mounted () {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  },
  destroyed () {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';

    //销毁心跳检测 定时器
    if (this.heartbeatInstance) {
      clearInterval(this.heartbeatInstance)
    }

    //主动断开websocket实例
    if (this.socket) {
      this.isDestroyed = true;
      this.socket.close();
    }
  },

  created: function () {
    // init sendMsg object
    if (this.$store.getters.userInfo.UserId) {
      getDefaultAccount(this.$store.getters.userInfo.UserId).then(
        (result) => {
          this.patientid = 'patient-' + result.patientId;
          this.doConnect();

          if (result.patientId != undefined && result.patientId != '' && result.patientId != null) {
            GetInforMationUnRead('patient-' + result.patientId).then(
              (result) => {
                this.num = result.unReadNum
              }, () => { });
          }
        }, () => { });
    }
  },

}
</script>

<style scoped="" lang="less">
@import "../../style/var.less";

body {
  overflow-y: hidden;
}

.hospitalTitle {
  text-align: center;
  margin: 0;
  padding: 6vh 0 5px;
  font-size: 28px;
  color: white;
  letter-spacing: 1px;
}
.van-icon {
  float: right;
  margin-right: 1.5rem;
}
.van-tabbar-item__icon img {
  height: 14px;
}
.mainContainer {
  position: relative;
  top: -2rem;
}
</style>