<template>
    <div>
        <div ref="pageTitle" :class="{ pageTitleFixed: fixed, background: !smallBackground }" :style="{ minHeight: height }">
            <van-nav-bar
                    v-if="navBar"
                    :title="title"
                    :left-arrow="leftArrow"
                    @click-left="onClickLeft"
                    :class="{ background: smallBackground }"
                    safe-area-inset-top
            />
            <slot></slot>
        </div>
        <div :style="{ minHeight: curtainHeight }" v-if="fixed"></div>
    </div>
</template>

<script>
    export default{
        props: {
            title: {
                type: String
            },
            navBar: {
                type: Boolean,
                default: true
            },
            height: {
                type: String,
                default: '0px'
            },
            fixed: {
                type: Boolean,
                default: false
            },
            smallBackground: {
                type: Boolean,
                default: false
            },
            backTo: {
                type: String,
                default: undefined
            },
            leftArrow: {
              type: Boolean,
              default: true
            }
        },
        data(){
            return {
                curtainHeight: ''
            }
        },
        watch:{
            height: function (newQuestion) {
                this.curtainHeight = newQuestion;
            }
        },
        methods: {
            onClickLeft(){
                this.backTo ? this.$router.push(this.backTo) : this.$router.go(-1);
            }
        },
        mounted(){
            this.curtainHeight = this.$refs.pageTitle.offsetHeight + 'px';
        }
    }
</script>

<style scoped lang="less">
    @import "../style/var.less";

    .background{
        // background-image: url("../assets/background.png");
        background-size: 100% 100%;
    }
    .pageTitleFixed{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        background-color: @white;
		color: #333333;
    }
    .van-nav-bar{
        background-color: transparent;
    }
    [class*='van-hairline']::after{
        border: none;
    }
</style>