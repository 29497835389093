<template>
    <li class="infoContainer" @click.stop="itemClick()">
        <van-badge :content="badgeNumber" max="99">
            <div v-if="$slots.top" class="top">
                <slot name="top"></slot>
            </div>
            <div v-if="$slots.left" class="left" :style="{ width: leftWidth + '%' }">
                <slot name="left"></slot>
            </div>
            <div v-if="$slots.right" class="right" :style="{ width: rightWidth + '%' }">
                <slot name="right"></slot>
            </div>
            <div v-if="$slots.bottom" class="bottom">
                <slot name="bottom"></slot>
            </div>
        </van-badge>
    </li>
</template>

<script>
    export default{
        props: {
            to: {
                type: String,
                default: undefined
            },
            badgeNumber: [Number, String],
            leftWidth: {
                type: Number,
                default: 27
            },
            rightWidth: {
                type: Number,
                default: 73
            }
        },
        methods:{
            itemClick(){
                this.$emit('click');
                if(this.to && this.$route.path != this.to) this.$router.push(this.to);
            }
        }
    }
</script>

<style scoped="" lang="less">
    @import "../../style/var.less";

    .van-badge__wrapper{
        display: block;
        color: @black;
        vertical-align: inherit;
    }

    .infoContainer{
        background-color: @white;
        border-radius: 8px;
        box-shadow: 0px 0px 5px 0px @gray-4;
        margin-bottom: 3vh;
    }
    .top{
        vertical-align: inherit;
    }
    .left{
        text-align: center;
        display: inline-block;
        box-sizing: border-box;
        padding-right: 5px;
        vertical-align: inherit;
    }
    .right{
        display: inline-block;
        box-sizing: border-box;
        padding-left: 5px;
        vertical-align: inherit;
    }
</style>